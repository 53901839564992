import * as React from "react";
import './HomeIllustration.css';

function HomeIllustration(props) {
  return (
    <div className="HomeIllustrationContainer">
        <svg
            data-name="Layer 1"
            viewBox="0 0 1100 700"
            xmlns="http://www.w3.org/2000/svg"
            className="CloudContainer"
        >
          <path
            d="M364.69 58.228a1.018 1.018 0 01-1.015-.947 35.99 35.99 0 00-35.797-33.35c-1.086 0-2.226.056-3.39.165a1.018 1.018 0 01-.978-.508 42.765 42.765 0 00-78.205 9.301 1.014 1.014 0 01-1.002.735l-.27-.008c-.141-.005-.282-.01-.424-.01a30.161 30.161 0 00-29.391 23.814 1.018 1.018 0 01-.997.808h-68.224a1.018 1.018 0 110-2.037h67.41a32.216 32.216 0 0131.167-24.622 44.801 44.801 0 0181.375-9.557 37.833 37.833 0 012.929-.117 38.034 38.034 0 0137.829 35.243 1.018 1.018 0 01-.945 1.087 1.06 1.06 0 01-.072.003zM352.573 71.285h-167.01a1.018 1.018 0 010-2.037h167.01a1.018 1.018 0 110 2.037zM226.297 86.051h-56.01a1.018 1.018 0 110-2.037h56.01a1.018 1.018 0 110 2.037z"
            fill="#cbcbcb"
            className="Cloud1"
          />
          <path
            d="M899.69 134.228a1.018 1.018 0 01-1.015-.947 35.99 35.99 0 00-35.797-33.35c-1.086 0-2.226.056-3.39.165a1.018 1.018 0 01-.978-.508 42.765 42.765 0 00-78.205 9.301 1.014 1.014 0 01-1.002.735l-.27-.008c-.141-.005-.282-.01-.424-.01a30.161 30.161 0 00-29.391 23.814 1.018 1.018 0 01-.997.808h-68.224a1.018 1.018 0 110-2.037h67.41a32.216 32.216 0 0131.167-24.622 44.801 44.801 0 0181.375-9.557 37.833 37.833 0 012.929-.117 38.034 38.034 0 0137.829 35.243 1.018 1.018 0 01-.945 1.087 1.06 1.06 0 01-.072.003zM887.573 147.285h-167.01a1.018 1.018 0 010-2.037h167.01a1.018 1.018 0 110 2.037zM761.297 162.051h-56.01a1.018 1.018 0 110-2.037h56.01a1.018 1.018 0 010 2.037z"
            fill="#cbcbcb"
            className="Cloud2"
          />
        </svg>
        <svg
          viewBox="0 0 128.724 351.06"
          xmlns="http://www.w3.org/2000/svg"
          className="LandingArrow"
        >
          <path
            d="M69.362 0v234.04l59.362-58.51-64.362 175.53L0 175.53l59.362 58.51V0h10z"
          />
        </svg>
        <svg
            data-name="Layer 2"
            width={909}
            height={529.321}
            viewBox="0 0 909 529.321"
            xmlns="http://www.w3.org/2000/svg"
            className={props.classes}
            {...props}
        >
          <circle
            cx={248.187}
            cy={402.14}
            r={44.682}
            fill="#5cadd6"
            className="Tree2"
          />
          <path
            d="M215.195 372.295a44.686 44.686 0 0074.454 47.29 44.686 44.686 0 11-74.454-47.29z"
            opacity={0.2}
            style={{
              isolation: "isolate",
            }}
            className="Tree2"
          />
          <path
            fill="#3f3d56"
            className="Tree2"
            d="M248.31 402.139h.122l2.21 126.682h-4.665l2.333-126.682z"
          />
          <circle
            cx={157.782}
            cy={273.408}
            r={90.087}
            fill="#5cadd6"
            className="Tree1"
          />
          <path
            d="M91.264 213.237a90.094 90.094 0 00150.112 95.346 90.095 90.095 0 11-150.112-95.346z"
            opacity={0.2}
            style={{
              isolation: "isolate",
            }}
            className="Tree1"
          />
          <path
            fill="#3f3d56"
            className="Tree1"
            d="M158.029 273.408h.248l4.455 255.413h-9.405l4.702-255.413z"
          />
          <path fill="#f0f0f0" d="M338.809 396.621h412.922v122.408H338.809z" />
          <path fill="#cacaca" d="M521.196 421.918h48.147v97.11h-48.147z" />
          <path fill="#f0f0f0" d="M362.882 252.18h364.775v112.615H362.882z" />
          <path
            d="M764.639 259.116H325.9a3.672 3.672 0 01-2.545-6.32l119.907-115.255V87.338a3.676 3.676 0 013.673-3.673H470.6a3.676 3.676 0 013.672 3.673v20.396l9.057-8.706a81.373 81.373 0 01110.8-1.842l172.964 155.527a3.673 3.673 0 01-2.455 6.403z"
            fill="#cacaca"
          />
          <path
            d="M459.176 280.742a23.257 23.257 0 0123.258 23.257v31.418h-46.515v-31.418a23.257 23.257 0 0123.257-23.257zM545.27 281.15a23.257 23.257 0 0123.257 23.257v31.418h-46.515v-31.418a23.257 23.257 0 0123.258-23.257zM631.363 281.558a23.257 23.257 0 0123.258 23.257v31.418h-46.515v-31.418a23.257 23.257 0 0123.257-23.257zM665.637 420.694a23.257 23.257 0 0123.258 23.258v31.418H642.38v-31.418a23.257 23.257 0 0123.257-23.258zM435.511 420.694a23.257 23.257 0 0123.257 23.258v31.418h-46.514v-31.418a23.257 23.257 0 0123.257-23.258z"
            fill="#fff"
          />
          <path
            d="M812.057 398.532l-77.46-34.274a3.264 3.264 0 00-1.32-.28h-369.16a3.263 3.263 0 00-1.229.24l-84.314 34.275a3.264 3.264 0 001.23 6.288h21.875v112.615h-2.448v11.425h492.078v-11.425h-4.08V404.781h23.507a3.264 3.264 0 001.32-6.249zM780.7 517.396H308.207V404.781H780.7zM428.575 330.929h59.572v9.793h-59.572zM515.892 330.929h59.572v9.793h-59.572zM603.209 330.929h59.572v9.793h-59.572z"
            fill="#cacaca"
          />
          <path
            fill="#cacaca"
            d="M635.851 472.105h59.572v9.793h-59.572zM404.909 472.105h59.572v9.793h-59.572z"
          />
          <path
            d="M908 529.32H1a1 1 0 010-2h907a1 1 0 010 2zM667.988 444.98l-.282-3.515c-.366-1.379-3.81 2.948-4.221 3.042a10.222 10.222 0 00-3.309-.15c.148-6.305-.822-3.446-3.64-.6-9.078 4.601-5.748 19.14 4.588 19.213 9.424.548 14.113-12.262 6.864-17.99z"
            fill="#3f3d56"
          />
          <path
            d="M669.264 459.24c3.76-.06 8.488 13.132-.42 13.2-4.609.036-12.769.935-12.769.935s-6.343-9.52-3.514-15.442z"
            fill="#3f3d56"
          />
          <path fill="#3f3d56" d="M660.996 466.212v6.834h3.195l2.535-6.959" />
          <path
            d="M664.191 473.17c-.065-.071-3.455.155-3.32-.124v-6.834c.565-.916.112 6.798.25 6.71h2.983c.147-.086 2.352-7.087 2.665-6.952a.124.124 0 01.075.16c-.119.085-2.414 7.084-2.653 7.04z"
            fill="#fff"
          />
          <path
            d="M671.896 472.627c.09 1.124-5.322 1.124-5.232 0-.09-1.123 5.323-1.123 5.232 0z"
            fill="#3f3d56"
          />
          <path
            d="M667.972 471.88c-.312.076-1.368-5.109 1.843-5.842-3.125 1.665-1.515 5.515-1.843 5.842z"
            fill="#fff"
          />
          <path
            d="M672.41 470.741c-.018-.025-.03-.051-.046-.077l-.408.015z"
            fill="#3f3d56"
          />
          <path
            d="M685.986 466.792c-1.84-2.738-5.396-1.493-6.787 1.011-1.65 3.022-8.7.775-7.82 4.832 4.402-.929 17.43 2.25 14.607-5.843z"
            fill="#3f3d56"
          />
          <path
            d="M660.58 462.07c-2.89.564-7.989-3.017-8.125-4.073a.124.124 0 01.212-.13 9.163 9.163 0 003.91 3.159c2.436 1.234 6.886 1.262 12.795-1.848a15.816 15.816 0 01-8.792 2.892zM661.12 455.747c-.652.203-1.219-1.372-.622-.872a.624.624 0 00.622.623c.533.053.507-.529.748-.747.415.333-.296 1.06-.748.996zM659.937 472.649c-.248.033-1.995-6.242-2.112-6.337a.125.125 0 01.08-.157c.327-.13 2.003 6.248 2.151 6.331a.125.125 0 01-.119.163z"
            fill="#fff"
          />
          <path
            d="M665.17 473.5c.09 1.123-5.323 1.123-5.233 0-.09-1.124 5.323-1.124 5.232 0z"
            fill="#3f3d56"
          />
          <path
            d="M660.934 473.375c.09 1.123-5.323 1.123-5.233 0-.09-1.123 5.323-1.123 5.233 0z"
            fill="#3f3d56"
          />
          <path
            d="M662.18 453.318c.031.494-2.026.494-1.994 0-.032-.494 2.026-.494 1.993 0zM654.482 453.346a3.047 3.047 0 014.664 0c3.213-2.98-1.54-7.958-4.665-4.885a3.38 3.38 0 000 4.885zM663.6 453.346a3.047 3.047 0 014.665 0c3.213-2.98-1.54-7.958-4.665-4.885a3.38 3.38 0 000 4.885z"
            fill="#fff"
          />
          <path
            d="M655.333 450.07a1.162 1.162 0 010 2.323 1.162 1.162 0 010-2.323zM664.451 450.07a1.162 1.162 0 010 2.323 1.162 1.162 0 010-2.323z"
            fill="#3f3d56"
          />
          <path
            d="M666.041 453.769a1.018 1.018 0 010 2.036 1.018 1.018 0 010-2.036zM656.841 453.769a1.018 1.018 0 010 2.036 1.018 1.018 0 010-2.036z"
            fill="#ff6584"
          />
          <path
            data-name="Path 438"
            d="M179.498 511.351c12.907 3.706 13.06-14.534 15.114-22.742-7.296 4.967-19.596 12.05-15.066 22.52"
            fill="#5cadd6"
            className="Leaf1"
          />
          <path
            data-name="Path 439"
            d="M180.21 525.503c-1.695-9.59-1.802-21.734 7.607-27.402.45-.284.864.429.416.712-9.084 5.494-8.815 17.227-7.227 26.47a.413.413 0 01-.795.219z"
            fill="#3f3d56"
            className="Leaf1"
          />
          <path
            data-name="Path 442"
            d="M184.387 518.719c5.907 7.928 14.131-2.688 18.995-6.58-6.488-.344-16.85-1.681-18.867 6.471"
            fill="#5cadd6"
            className="Leaf1"
          />
          <path
            data-name="Path 443"
            d="M178.57 527.933c3.339-6.391 8.67-13.62 16.798-12.707.528.046.396.86-.131.814-7.72-.799-12.743 6.145-15.914 12.229-.258.457-1.015.127-.753-.336z"
            fill="#3f3d56"
            className="Leaf1"
          />
          <path
            data-name="Path 438"
            d="M835.265 511.351c-12.906 3.706-13.06-14.534-15.113-22.742 7.295 4.967 19.596 12.05 15.066 22.52"
            fill="#5cadd6"
            className="Leaf2"
          />
          <path
            data-name="Path 439"
            d="M834.553 525.503c1.696-9.59 1.803-21.734-7.606-27.402-.45-.284-.864.429-.416.712 9.084 5.494 8.814 17.227 7.227 26.47a.413.413 0 00.795.219z"
            fill="#3f3d56"
            className="Leaf2"
          />
          <path
            data-name="Path 442"
            d="M830.376 518.719c-5.906 7.928-14.13-2.688-18.994-6.58 6.487-.344 16.85-1.681 18.867 6.471"
            fill="#5cadd6"
            className="Leaf2"
          />
          <path
            data-name="Path 443"
            d="M836.194 527.933c-3.339-6.391-8.67-13.62-16.798-12.707-.528.046-.396.86.13.814 7.721-.799 12.744 6.145 15.914 12.229.26.457 1.015.127.754-.336z"
            fill="#3f3d56"
            className="Leaf2"
          />
          <path
            data-name="Path 438"
            d="M889.535 498.615c-22.499 6.46-22.765-25.337-26.345-39.645 12.717 8.66 34.159 21.005 26.262 39.258"
            fill="#5cadd6"
            className="Leaf3"
          />
          <path
            data-name="Path 439"
            d="M888.294 523.284c2.955-16.716 3.142-37.887-13.26-47.766-.783-.495-1.505.747-.725 1.24 15.836 9.578 15.366 30.03 12.598 46.143a.72.72 0 001.386.382z"
            fill="#3f3d56"
            className="Leaf3"
          />
          <path
            data-name="Path 442"
            d="M881.013 511.458c-10.296 13.82-24.634-4.685-33.112-11.468 11.31-.601 29.374-2.932 32.889 11.279"
            fill="#5cadd6"
            className="Leaf3"
          />
          <path
            data-name="Path 443"
            d="M891.153 527.52c-5.82-11.14-15.113-23.74-29.282-22.15-.92.08-.69 1.498.229 1.419 13.458-1.393 22.214 10.71 27.74 21.317.451.798 1.77.221 1.313-.586z"
            fill="#3f3d56"
            className="Leaf3"
          />
          <path
            d="M65.472 459.343l2.369-21.738 3.75-15.271-6.985-2.163-3.682 17.186 1.217 21.146c-2.297.785-2.883 3.762-1.056 5.359 1.828 1.597 4.7.617 5.169-1.765a3.155 3.155 0 00-.782-2.754z"
            fill="#ffbdc0"
          />
          <path
            d="M59.608 461.738c.49-7.208 3.951-14.158 10.062-20.879l16.35 1.006.252 12.578 13.081 69.428-4.528.251-21.382-45.027-10.062 44.776-4.528.251z"
            fill="#2f2e41"
          />
          <circle cx={76.21} cy={403.585} r={7.798} fill="#ffbdc0" />
          <path
            d="M66.61 435.092a26.769 26.769 0 01-2.8-15.552 6.763 6.763 0 014.602-5.597l9.056.252 6.54 7.295c3.131 3.83 5.084 7.777 3.522 12.074l-1.51 9.559-16.853-1.006z"
            fill="#e6e6e6"
          />
          <path
            d="M55.633 424.654c3.65-1.576 3.718-4.854 2.303-8.837-1.875-3.762-.511-7.31 2.795-10.729 1.727-1.517 2.374-3.326 2.133-5.375a5.979 5.979 0 011.711-5.027c1.288-1.239 7.29-2.006 9.168-2.075l2.455-.089a7.445 7.445 0 017.715 7.544c-3.929-.951-4.408.172-6.56 2.685a9.684 9.684 0 00-2.199 6.73c.137 3.302-2.121 8.638-4.145 13.776 2.387 4.137-.569 7.441-3.97 10.388h-.181c-4.701 0-12.41-4.442-11.225-8.991z"
            className="Hair"
            fill="#593016"
          />
          <ellipse cx={76.084} cy={405.094} rx={1.006} ry={1.887} fill="#ffbdc0" />
          <path
            d="M90.9 462.993l-5.634-21.128-2.012-15.596-7.296.503 2.767 17.357 8.765 19.283c-1.859 1.56-1.332 4.548.949 5.378 2.281.831 4.605-1.12 4.184-3.511a3.157 3.157 0 00-1.723-2.286z"
            fill="#ffbdc0"
          />
          <path
            d="M73.284 419.481a5.193 5.193 0 015.44-5.274 5.194 5.194 0 014.946 5.277l.841 9.552c-3.082-2.326-6.185-2.137-9.307.252z"
            fill="#e6e6e6"
          />
          <path
            d="M94.463 523.575h6.343a4.04 4.04 0 014.041 4.041v.132H94.464zM58.739 523.575h6.342a4.042 4.042 0 014.042 4.041v.132H58.739z"
            fill="#2f2e41"
          />
        </svg>
    </div>
  )
}

export default HomeIllustration;
