import React from "react";
import "./Cats.css";

function Cats() {
  return (
    <div className="CatsContainer">
        <svg className="CatsBlob" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
  <path fill="#2E576B" d="M31.3,-51.7C44.5,-46.7,61.9,-46.2,71.6,-38.3C81.3,-30.5,83.5,-15.2,79.4,-2.4C75.3,10.5,64.9,21,58.8,35.3C52.8,49.6,51.2,67.8,42.2,76.2C33.1,84.6,16.5,83.3,0.5,82.4C-15.6,81.6,-31.2,81.3,-43.9,75C-56.7,68.7,-66.7,56.5,-68.3,43C-69.9,29.5,-63.1,14.7,-60.4,1.5C-57.8,-11.7,-59.4,-23.4,-55.7,-33.3C-51.9,-43.1,-43,-51,-32.8,-57.8C-22.7,-64.7,-11.3,-70.4,-1.1,-68.4C9.1,-66.4,18.1,-56.8,31.3,-51.7Z" transform="translate(100 100)" />
</svg>

      <svg
        className="Cat1 Cat"
        viewBox="55.23 104.39 242.373 158.923"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <clipPath id="prefix__a">
            <path d="M0 1000h1000V0H0z" fill="none" />
          </clipPath>
        </defs>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M260.745 886.056s23.159 14.72 32.466 3.896c0 0 8.874-9.398-10.822-26.235z"
            fill="#cbb9aa"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M267.291 885.087c4.172 2.104 10.614 4.81 16.13 4.81 3.045 0 5.248-.84 6.737-2.57l.146-.17-.021.03c1.704-2.079 2.107-8.433-7.71-17.873z"
            fill="#fff2e8"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M209.632 886.056s-23.159 14.72-32.466 3.896c0 0-8.874-9.398 10.822-26.235z"
            fill="#cbb9aa"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M203.085 885.087c-4.172 2.104-10.614 4.81-16.129 4.81-3.045 0-5.248-.84-6.737-2.57l-.146-.17.021.03c-1.704-2.079-2.107-8.433 7.71-17.873z"
            fill="#fff2e8"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M64.975 793.886s4.804 28.02 47.636 39.629c0 0 21.616 8.809 76.858 3.204l89.267-17.613 18.413-4.804s5.204-50.44-19.614-59.645l-43.633-6.805s-34.827.4-45.235 0c0 0-33.224 2-42.83 2 0 0-50.439.802-57.244 3.204l-16.012 12.809z"
            fill="#ccb9ab"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M176.083 837.848s-7.701-18.508-3.337-37.975c0 0 4.363-23.83 38.261-30.878 0 0 29.201-10.74 61.086 3.356 0 0 23.598 7.712 25.056 41.951 0 0 1.044 13.785-7.307 27.536l-39.23-22.498-49.338 3.356-23.207 15.014z"
            fill="#ebd9cd"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M234.3 895.585s-50.829 2.343-54.506-38.654l2.172-21.743 13.414-14.058 24.044-8.009s25.953.866 26.603.866c.65 0 34.63 15.367 34.63 15.367l6.277 9.23 1.604 13.648s6.758 40.538-54.239 43.353"
            fill="#ebd9cd"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M179.794 856.93s-1.007-31.548 38.933-30.206c0 0 8.39-9.397 17.117-9.397 0 0 12.754 0 17.453 11.41 0 0 27.186-9.061 35.242 23.495 0 0 10.068-40.276-24.837-45.646 0 0-11.233-.875-18.124 2.35-5.016 2.348-13.09 2.014-22.152-.336 0 0-11.411-4.7-25.173 1.007 0 0-27.186 7.72-18.46 47.324"
            fill="#ccb9ab"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M166.033 806.477s2.252-23.385 12.034-35.132c0 0 42.339-11.076 41.332-27.522 0 0 1.342-8.055-15.44-7.049 0 0-22.23 9.042-33.938 9.556 0 0-21.105.178-21.441 21.323 0 0-.558 24.594 17.453 38.824"
            fill="#ebd9cd"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M279.375 755.448s-31.189 11.748-45.076 2.136c0 0-14.9-9.398 2.888-16.11 0 0 27.467-6.23 42.188 13.974"
            fill="#ebd9cd"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M70.668 806.477s-25.799-18.015-10.695-42.852c0 0 15.86-24.106 52.36-7.719 0 0 29.199 11.075 33.227 29.535 0 0 3.692 12.42-12.754 6.041 0 0-8.726-3.356-22.488-8.054 0 0-47.751-8.273-39.65 23.05"
            fill="#ebd9cd"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M85.447 783.595s-9.364-19.304 3.39-33.398c0 0 10.63 0 19.465 4.034 0 0-16.805 11.238-7.73 28.272 0 0-9.783-.545-15.125 1.092"
            fill="#685a50"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M108.303 783.136s-3.101-13.637 10.869-24.126c0 0 9.327 4.802 13.6 8.572 0 0-10.172 7.663-7.531 21.096l-14.923-5.25z"
            fill="#685a50"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M132.805 791.482s-1.878-11.624 8.123-15.713c0 0 7.575 9.56 4.033 15.713 0 0-1.864 4.25-12.156 0"
            fill="#685a50"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M235.205 873.069s-16.666-17.062-6.71-18.704h15.584s7.143 2.903-8.874 18.704"
            fill="#cab5a9"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M235.495 861.813s-9.38 3.247-15.009-17.748c0 0-16.413-11.542-12.605-16.809 0 0 10.58-8.94 20.575 1.77 0 0 6.03 4.001 6.605 9.845 0 0 5.004-12.136 15.846-14.275 0 0 10.703-.876 12.867 4.751 0 0-.02 4.76-9.858 11.038 0 0-3.994 3.462-5.726 7.791 0 0-3.045 15.425-12.695 13.637"
            fill="#fff3e9"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M238.092 836.514c0-5.824-1.163-6.517-2.597-6.517-1.435 0-2.598.693-2.598 6.517 0 5.822 2.598 14.572 2.598 14.572s2.597-8.75 2.597-14.572"
            fill="#cebaac"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M241.481 851.086c0-1.59-2.665-2.877-5.953-2.877-3.286 0-5.952 1.287-5.952 2.877 0 1.589 2.666 2.876 5.952 2.876 3.288 0 5.953-1.287 5.953-2.876"
            fill="#f49c92"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M266.24 856.53a8.586 8.586 0 10-17.172 0 8.586 8.586 0 0017.171 0"
            fill="#9f8b43"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M260.745 856.543c0-3.014-1.661-5.457-3.71-5.457-2.05 0-3.71 2.443-3.71 5.457 0 3.01 1.66 5.451 3.71 5.451 2.049 0 3.71-2.441 3.71-5.451"
            fill="#393c37"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M263.267 859.07a2.521 2.521 0 10-5.044 0 2.521 2.521 0 002.522 2.521 2.521 2.521 0 002.522-2.52"
            fill="#fff"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M221.28 856.528a8.586 8.586 0 10-17.171 0 8.586 8.586 0 0017.171 0"
            fill="#9f8b43"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M215.786 856.542c0-3.014-1.661-5.457-3.71-5.457-2.05 0-3.71 2.443-3.71 5.457 0 3.01 1.66 5.45 3.71 5.45 2.049 0 3.71-2.44 3.71-5.45"
            fill="#393c37"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M218.308 859.07a2.521 2.521 0 10-5.043-.002 2.521 2.521 0 005.043.001"
            fill="#fff"
          />
        </g>
      </svg>
      <svg
        className="Cat2 Cat"
        viewBox="390.636 110.608 253.425 152.543"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <clipPath id="prefix__a">
            <path d="M0 1000h1000V0H0z" fill="none" />
          </clipPath>
        </defs>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M472.144 868.635s22.764 25.508 31.49 19.97c0 0 4.786-7.048-12.754-47.157z"
            fill="#bb7533"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M477.286 868.271c7.132 7.514 17.609 16.521 22.926 17.066.133-3.344-.781-12.867-10.17-35.576z"
            fill="#e6a168"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M431.603 868.635s-22.764 25.508-31.491 19.97c0 0-4.786-7.048 12.754-47.157z"
            fill="#3d3d3d"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M426.46 868.271c-7.131 7.514-17.608 16.521-22.926 17.066-.132-3.344.782-12.867 10.17-35.576z"
            fill="#5c5c5c"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M422.411 765.65s-23.046-1.79-30.654-13.648c0 0-4.252-11.86 3.803-11.86 0 0 7.78.108 13.4 4.194 0 0 20.611 12.14 25.534 11.693z"
            fill="#d9af93"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M490.752 825.002s4.63-9.648 18.138-9.263l14.666-.01 23.928 4.636 42.065 13.898s11.578-2.699 19.682-8.489l11.579-16.595 14.66-14.28s9.653-11.96 8.495-32.802c0 0-.386-12.35-11.192-16.595 0 0-121.28-2.33-184.186-.403l-16.778 7.574-6.712 6.131s-13.09 6.288-4.7 32.802l16.782 7.385 27.858 9.733z"
            fill="#d88944"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M508.668 777.175s-9.733 17.117 6.377 15.775c16.11-1.343-1.678-12.754-6.377-15.775"
            fill="#3a3a3a"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M508.89 815.74s33.328 20.569 80.659 18.524c0 0 5.377-14.463-17.11-21.847 0 0-5.37-1.678-13.761 0 0 0-9.062 1.342-5.706-7.385 0 0 7.72-5.63 5.034-19.26 0 0-1.678-21.351-16.11-22.693 0 0-13.761-2.685-17.788 16.446 0 0-3.357 12.838 10.069 9.775 0 0 10.068-4.07 12.082 7.342 0 0 0 17.117-11.411 7.384 0 0-12.073-11.747-21.141-2.35 0 0-5.266 4.969-4.817 14.064"
            fill="#3a3a3a"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M606.633 827.454s24.743-16.509 28.838-32.555c0 0 2.282-20.409-1.343-27.122 0 0-3.693-7.384-12.722-7.048 0 0-14.142-1.007-15.78 10.405 0 0 .376 4.698 5.41 12.082 0 0 2.35 8.055-.336 13.426 0 0-10.74 10.74-11.075 18.124 0 0-1.76 9.602 7.008 12.688"
            fill="#3a3a3a"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M606.673 786.908s-1.678 10.74-12.754 16.446c0 0-14.097 2.058-18.124-4.676 0 0-3.02.984-7.72 2.327 0 0-8.726 3.602-5.034-6.925 0 0 4.892-16.905 1.439-24.289 0 0-9.495-6.042-6.81-14.097 0 0 8.392-7.384 19.132 0 0 0 10.404 12.083 15.774 13.09 0 0 18.119 9.148 14.097 18.124"
            fill="#3a3a3a"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M415.027 827.454s-5.035-29.134 10.404-47.93c0 0 24.166-25.843 48.667 0 0 0 30.542 29.871 17.788 51.688z"
            fill="#eeccb2"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M493.632 843.294c-8.39 33.9-41.35 33.228-41.35 33.228s-33.563.671-41.954-33.228c0 0-4.926-9.397 3.914-22.823 0 0 13.177-12.754 16.38-19.13 0 0 10.92-8.914 21.324-8.652 10.405-.262 21.394 8.651 21.394 8.651 3.201 6.377 16.378 19.131 16.378 19.131 8.84 13.426 3.914 22.823 3.914 22.823"
            fill="#d88944"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M458.08 823.074a10.046 10.046 0 01-7.349-3.193 10.046 10.046 0 01-7.348 3.193c-5.569 0-10.082-4.514-10.082-10.083 0-5.57 4.513-10.084 10.082-10.084 2.901 0 5.508 1.233 7.348 3.194a10.046 10.046 0 017.348-3.194c5.568 0 10.082 4.515 10.082 10.084s-4.514 10.083-10.082 10.083"
            fill="#fff"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M450.731 792.684s1.271 18.214 12.32 2.528c0 0-4.384-7.012-12.32-2.528"
            fill="#3a3a3a"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M445.884 867.644s-.524 7.87-14.583 1.97c0 0-9.284-6.743-9.003-11.798 0 0-.711-5.185 10.537 3.842 0 0 11.343-2.556 13.049 5.986"
            fill="#3a3a3a"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M449.253 871.808s24.71 3.98 37.907-26.345c0 0 5.054-20.498-13.478-26.113 0 0-17.971 1.965-14.601 23.305 0 0 .359 4.493 3.97 6.178 0 0 5.296 4.898-3.97 8.907 0 0-17.004 9.629-9.828 14.068"
            fill="#3a3a3a"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M454.844 828.857c0-8.406-1.841-11.194-4.112-11.194s-4.114 2.788-4.114 11.194c0 8.41 1.843 19.253 4.114 19.253 2.27 0 4.112-10.842 4.112-19.253"
            fill="#efcbae"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M456.89 820.752c0-2.404-2.778-4.353-6.204-4.353-3.427 0-6.206 1.949-6.206 4.353 0 2.403 2.779 4.352 6.206 4.352 3.426 0 6.205-1.949 6.205-4.352"
            fill="#e28765"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M431.845 852.764s9.266 1.684 10.95-17.41c0 0 1.966-11.083-11.792-12.84 0 0-12.1-2.645-15.443 12.09 0 0 1.965 11.984 16.285 18.16"
            fill="#edccb5"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M440.191 836.338a8.048 8.048 0 10-16.095.002 8.048 8.048 0 0016.095-.002"
            fill="#9e943a"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M435.12 836.75c0-3.06-1.333-5.538-2.976-5.538s-2.975 2.479-2.975 5.538 1.332 5.538 2.975 5.538 2.975-2.48 2.975-5.538"
            fill="#37383b"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M437.45 838.267a2.331 2.331 0 00-4.663 0 2.333 2.333 0 104.664 0"
            fill="#fff"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M479.548 836.338a8.048 8.048 0 10-16.096.002 8.048 8.048 0 0016.096-.002"
            fill="#9e943a"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M474.475 836.75c0-3.06-1.332-5.538-2.975-5.538s-2.975 2.479-2.975 5.538 1.332 5.538 2.975 5.538 2.975-2.48 2.975-5.538"
            fill="#37383b"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M476.807 838.267a2.331 2.331 0 00-4.664 0 2.332 2.332 0 104.664 0"
            fill="#fff"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M493.117 744.394s21.48 10.516-7.383 20.361c0 0-29.974 3.931-57.5-3.74 0 0-25.737-2.525-27.751-15.055 0 0 1.567-4.698.896-7.383 0 0 3.576-4.031 11.745.222 0 0 13.599 6.717 37.519 6.24 0 0 40.46-.633 42.474-.645"
            fill="#ecccb3"
          />
        </g>
      </svg>
      <svg
        className="Cat3 Cat"
        viewBox="748.632 50.407 151.744 240.605"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <clipPath id="prefix__a">
            <path d="M0 1000h1000V0H0z" fill="none" />
          </clipPath>
        </defs>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M891.226 907.639s15.439 38.766 6.209 41.954c0 0-21.596-15.607-28.67-20.977z"
            fill="#3a3a3a"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M873.873 925.775c6.367 4.716 16.216 11.868 21.364 15.598.353-5.028-2.409-16.924-6.813-29.187z"
            fill="#818181"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M810.994 906.797s-15.439 38.766-6.209 41.954c0 0 21.596-15.607 28.67-20.977z"
            fill="#3a3a3a"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M828.348 924.933c-6.368 4.716-16.217 11.868-21.365 15.598-.353-5.028 2.409-16.924 6.813-29.187z"
            fill="#818181"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M880.26 797.882s-2.87-45.911-5.738-72.213c0 0 14.346-.956 14.346-10.998 0 0-4.781-11-25.823-2.392 0 0-8.133 12.91-3.827 54.995z"
            fill="#838383"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M831.002 846.659s-18.649 12.434-41.605-8.13c0 0-29.171-31.565-31.084-76.517l-2.391-25.824 19.606-15.781h39.216s18.171-2.391 27.258 6.695c0 0 13.266 9.567 15.958 28.695 0 0 26.13 19.607 27.563 61.69 0 0 3.824 28.693-9.088 27.259z"
            fill="#636363"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M758.445 764.426s-14.478-13.415-8.263-31.109c0 0 5.258-19.008 27.258-23.372 0 0 39.694-2.929 51.172 17.156 0 0 17.694 28.216-2.87 13.39 0 0-11.716-13.37-24.868-16.259-9.857-2.166-26.863-3.908-38.258 12.88 0 0-6.904 8.886-4.17 27.314"
            fill="#494949"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M830.554 721.247s.937 18.676 17.43 11.788c0 0 17.929-9.76 5.973-15.497 0 0-17.635-5.495-23.403 3.709"
            fill="#494949"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M810.44 812.705s-15.304-14.362-14.348-36.113c0 0 2.68-31.122 39.598-17.325 0 0-3.25-14.925 5.355-14.937 0 0 12.433-3.778 12.433 9.104 0 0 1.913 19.58-11.955 19.58 0 0-10.52 3.331-23.433 1.665 0 0-3.916 1.9-7.65 38.026"
            fill="#828282"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M820.004 875.831s-12.39-17.215.979-47.344c0 0 10.196-18.174 32.585-14.825 0 0 29.687 2.243 30.342 26.23 0 0 5.915 23.982-5.561 35.94z"
            fill="#494949"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M850.106 935.132s-40.133.138-43.014-43.518c0 0-3.734-8.922 17.717-27.26 0 0 13.543-14.547 28.28-14.446 0 0 16.212 2.194 30.3 17.407 0 0 16.956 14.256 12.654 31.472 0 0 .7 32.519-45.938 36.345"
            fill="#636363"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M859.96 877.03c-3.624 0-6.732-2.336-8.084-5.676-1.353 3.34-4.461 5.677-8.083 5.677-4.864 0-8.807-4.21-8.807-9.406 0-5.194 3.943-9.405 8.807-9.405 3.622 0 6.73 2.337 8.083 5.677 1.352-3.34 4.46-5.677 8.084-5.677 4.862 0 8.805 4.21 8.805 9.405 0 5.196-3.943 9.406-8.805 9.406"
            fill="#838383"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M878.61 894.246a8.678 8.678 0 00-8.679-8.679 8.677 8.677 0 00-8.679 8.68 8.68 8.68 0 1017.358 0"
            fill="#e4c87d"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M872.753 894.248c0-3.406-1.263-6.165-2.821-6.165-1.56 0-2.822 2.759-2.822 6.165 0 3.404 1.263 6.162 2.822 6.162 1.558 0 2.82-2.758 2.82-6.162"
            fill="#11151d"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M874.758 896.253a2.005 2.005 0 10-4.01-.002 2.005 2.005 0 004.01.002"
            fill="#fff"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M841.56 894.248a8.678 8.678 0 00-8.68-8.68 8.677 8.677 0 00-8.678 8.68 8.68 8.68 0 1017.359 0"
            fill="#e4c87d"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M835.702 894.249c0-3.406-1.263-6.165-2.82-6.165-1.56 0-2.823 2.759-2.823 6.165 0 3.403 1.263 6.162 2.822 6.162 1.558 0 2.821-2.759 2.821-6.162"
            fill="#11151d"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M837.707 896.255a2.004 2.004 0 10-4.009-.003 2.004 2.004 0 004.009.003"
            fill="#fff"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M858.506 876.125c0-2.101-2.923-6.327-6.63-6.327-3.708 0-6.796 4.226-6.796 6.327 0 2.1 3.005 3.802 6.713 3.802s6.713-1.702 6.713-3.802"
            fill="#3c3c3c"
          />
        </g>
      </svg>
      <svg
        className="Cat4 Cat"
        viewBox="70.623 375.742 211.598 256.407"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <clipPath id="prefix__a">
            <path d="M0 1000h1000V0H0z" fill="none" />
          </clipPath>
        </defs>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M227.241 584.85s9.32 28.18 4.477 36.577c-4.717 8.176-21.486-2.798-34.84-14.55z"
            fill="#8b5b44"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M202.542 605.062c9.9 8.163 18.508 13.098 23.027 13.098 1.348 0 1.62-.473 1.819-.817 2.102-3.646.112-16.38-3.187-27.993z"
            fill="#cebaac"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M145.768 583.519s-10.873 27.618-6.503 36.27c4.255 8.426 21.608-1.6 35.595-12.59z"
            fill="#8b5b44"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M169.305 605.071c-10.337 7.6-19.208 12.048-23.72 11.797-1.344-.075-1.59-.56-1.77-.917-1.896-3.757.798-16.359 4.738-27.772z"
            fill="#cebaac"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M227.247 556.391s-.001-16.558 9.845-29.983l-47.884-26.404-32.668-33.563s-1.343 16.11-5.37 26.85c0 0-4.923-4.922-10.293-6.713 0 0 7.16 14.323 2.238 20.587 0 0-8.056-8.95-10.741-8.95 0 0 5.819 6.713 6.714 17.901 0 0-.001 25.954 9.397 40.275h51.91z"
            fill="#ccb9ab"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M149.818 402.022s-11.188 56.387 21.928 94.424c0 0 31.102 38.364 62.092 36.86 0 0 26.516.285 43.073-59.235 0 0 17.005-66.713-9.845-86.832 0 0-29.088-23.252-67.127-12.961z"
            fill="#ebd9cd"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M245.086 376.227s3.557 17.27-30.857 25.773c0 0-32.069 5.43-49.182 6.266 0 0-25.06.895-35.353 12.977 0 0-2.237-3.131 5.371-13.424 0 0-15.664 7.158-24.614 10.29l6.265-8.054s-30.429 3.58-46.093-19.242c0 0 18.348-.897 19.243-4.027l-9.846-6.266s18.35-.084 35.354-4.293c0 0 28.193-8.686 51.016-8.238 0 0 64.758-1.873 78.696 8.238"
            fill="#ccb9ab"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M232.271 575.976c-10.892 36.678-46.236 34.01-46.236 34.01s-35.345 2.668-46.237-34.01c-3.166-10.661 4.89-32.232 28.676-34.01 0 0 6.89-6.224 17.561-6.224 10.67 0 17.561 6.224 17.561 6.224 23.785 1.778 31.841 23.349 28.675 34.01"
            fill="#ebd9cd"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M199.722 594.023c-2.691 6.782-7.81 11.37-13.687 11.37-5.96 0-11.142-4.705-13.802-11.638-14.698-3.827-25.12-13.379-25.12-24.565 0-14.556 17.64-24.746 39.396-24.746 21.755 0 39.395 10.19 39.395 24.746 0 11.454-10.926 21.198-26.182 24.833"
            fill="#875844"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M198.276 569.75c-1.736 0-3.39-.298-4.933-.8.072 1.04.116 2.106.116 3.201 0 9.937-6.951 17.995-6.951 17.995s-6.95-8.058-6.95-17.995c0-1.32.058-2.605.162-3.844a15.729 15.729 0 01-6.4 1.364c-8.096 0-14.66-5.946-14.66-13.28 0-7.335 6.564-13.28 14.66-13.28 5.3 0 9.929 2.556 12.505 6.372 2.587-3.772 7.188-6.293 12.45-6.293 8.097 0 14.663 5.946 14.663 13.28s-6.566 13.28-14.662 13.28"
            fill="#fff"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M189.43 548.087c0-4.235-1.52-3.643-3.395-3.643s-3.396-.592-3.396 3.643c0 4.236 3.396 11.7 3.396 11.7s3.396-7.464 3.396-11.7"
            fill="#cebaaf"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M192.117 560.946c0-1.897-2.555-3.436-5.707-3.436-3.15 0-5.705 1.539-5.705 3.436s2.555 3.436 5.705 3.436c3.152 0 5.707-1.54 5.707-3.436"
            fill="#de907a"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M210.232 580.142a6.676 6.676 0 00-6.677-6.677 6.676 6.676 0 100 13.353 6.676 6.676 0 006.677-6.676"
            fill="#78c7e1"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M206.073 580.142c0-2.601-1.127-4.71-2.518-4.71-1.39 0-2.518 2.109-2.518 4.71 0 2.6 1.128 4.709 2.518 4.709s2.518-2.11 2.518-4.71"
            fill="#333331"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M206.893 582.56a1.596 1.596 0 10-3.192-.001 1.596 1.596 0 003.192 0"
            fill="#fff"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M176.474 580.142a6.676 6.676 0 00-6.677-6.677 6.676 6.676 0 100 13.353 6.676 6.676 0 006.677-6.676"
            fill="#78c7e1"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M172.314 580.142c0-2.601-1.127-4.71-2.518-4.71-1.39 0-2.518 2.109-2.518 4.71 0 2.6 1.128 4.709 2.518 4.709 1.391 0 2.518-2.11 2.518-4.71"
            fill="#333331"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M173.134 582.56a1.596 1.596 0 10-3.192-.001 1.596 1.596 0 003.192 0"
            fill="#fff"
          />
        </g>
      </svg>
      <svg
        className="Cat5 Cat"
        viewBox="354.923 377.584 236.062 235.201"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <clipPath id="prefix__a">
            <path d="M0 1000h1000V0H0z" fill="none" />
          </clipPath>
        </defs>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M488.638 574.537s-19.466 18.886-15.103 43.387c0 0 1.007 9.398 13.425 1.007 0 0 19.313-13.052 29.459-21.126z"
            fill="#d8884d"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M489.04 580.126c-4.74 5.76-14.565 20.106-11.54 37.091l.024.138.015.14c.145.893.568.893.877.893.648 0 2.556-.272 6.29-2.794.167-.114 14.985-10.138 25.29-17.915z"
            fill="#e1c7ae"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M575.243 573.622s19.467 18.886 15.104 43.387c0 0-1.007 9.398-13.425 1.007 0 0-19.314-13.052-29.459-21.126z"
            fill="#d8884d"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M574.842 579.212c4.74 5.76 14.564 20.106 11.54 37.09l-.024.139-.016.139c-.145.893-.568.893-.877.893-.647 0-2.556-.272-6.288-2.794-.17-.114-14.987-10.138-25.291-17.915z"
            fill="#e1c7ae"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M490.876 535.805s-33.562.45-52.806 4.923c0 0-36.249 8.95-52.359-2.462L362.888 518.8s-1.267-3.166-1.769-6.516l-6.196-1.004 6.866-6.364s-5.526-1.172-6.866-3.515c0 0 7.871-2.346 8.038-4.02 0 0-5.359-3.014-7.033-5.694 0 0 9.041-1.34 9.209-3.683 0 0-5.357-.67-6.028-4.186 0 0 10.382 1.172 12.392-4.353 0 0-5.694.67-7.369-2.346 0 0 12.057-2.68 11.221-12.057l-6.196-3.179s6.865-2.346 7.367-6.364l-7.702-1.006s4.521-2.51 5.023-5.693l-2.846-3.349s3.181-29.135 15.071-41.862l6.53 4.856 4.689 9.545s-6.194 9.377-4.017 23.777c0 0 8.036 14.066 10.213 6.03 0 0 6.68-18.42 16.569-35.835 0 0 3.191-8.203 12.735-9.043 0 0 10.215-1.004 15.908.67 0 0 3.014 2.176 2.177 6.53 0 0-.502 9.544-14.903 9.042 0 0-13.898 17.081-11.219 31.65 0 0 11.052 5.86 13.396 8.204 0 0 1.34 5.693 4.521-.502 0 0 6.029 7.87 9.545 4.69 3.516-3.182-2.511-1.676 3.014-7.202 0 0 8.206 6.866 12.225 5.36 0 0-.503-6.028 1.506-7.535 0 0 3.016 7.367 12.728 4.687 0 0-.504-4.018 1.841-6.027 0 0 8.539 7.87 6.028 1.674 0 0-5.525-1.34-5.86-4.522 0 0 2.512-1.338 6.028-1.172 0 0-6.363-2.512-6.865-4.687 0 0 7.033-.002 7.2-7.87 0 0-2.385-23.445.817-39.017 0 0 .858-8.709 13.584-9.21 0 0 16.409-2.177 20.428 2.68 0 0 5.44 10.204-15.07 15.74 0 0-3.684 23.61 3.851 36.671 0 0 3.852 9.211 5.191 13.73 0 0 3.818-7.87 6.682-14.4 0 0 .52-31.315 2.193-38.18 0 0-.334-10.55 12.727-12.558 0 0 14.569-2.344 21.602 3.515 0 0 7.033 11.053-16.745 14.905 0 0-4.019 17.416 2.846 36.003 0 0 5.694 12.893 7.368 23.946l5.024-2.344s1.172 9.043-.335 11.555c0 0 4.186-2.01 7.2-3.014 0 0-.502 5.023-3.851 10.55 0 0-2.176 2.677 0 5.188 0 0 2.344-4.353 7.368-3.013 0 0-3.517 12.563-1.172 18.925l-9.377 7.871-13.899 3.35-41.025 12.223z"
            fill="#e4d2be"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M410.772 544.062s-8.427 5.188-24.849.005c0 0 4.537 19.234-14.909 27.012 0 0-12.965 3.24-13.613-11.453 0 0 .431-21.391 14.044-23.12 0 0-8.769-4.146-10.326-24.221 0 0 2.713 3.322 3.844 6.287 0 0 1.297-5.185 3.242-8.643 0 0 7.132 29.156 42.567 34.133"
            fill="#d4854a"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M426.329 531.752s-12.962 4.539-16.637-20.743c0 0-1.297-15.126 8.318-15.989 0 0 17.177 8.857 21.067 28.305 0 0-.648 9.724-12.748 8.427"
            fill="#d4854a"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M384.522 405.435s.752 4.883 3.346 4.666c0 0-1.15 13.881 6.988 13.207 0 0 4.248-5.859 4.68-10.613 0 0 16.206.215 15.99-13.182 0 0-1.081-6.266-12.75-5.402 0 0-15.768-.04-18.255 11.324"
            fill="#d4854a"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M487.072 545.427s-8.28.224-11.188-6.042c0 0 17.23-.76 0-7.316 0 0 1.566-3.647 9.62-4.766 0 0-5.816-4.699-5.144-9.846 0 0 17.228 6.49 5.593-11.635 0 0 2.909-1.566 8.279 1.566 0 0 4.923-8.628 8.279-12.369 0 0 2.46 2.076 3.133 6.776 0 0 16.109-11.637 40.275-1.119 0 0-.224-3.58 2.685-6.713l7.385 8.504s3.13-4.252 7.383-5.596c0 0-.895 10.517 0 12.083 0 0 6.407-3.803 8.46-6.264 0 0 2.28 12.083-2.42 17.006 0 0 6.714 1.79 13.203 2.013 0 0-2.908 5.37-9.844 6.713 0 0 2.012 3.804 9.844 3.356 0 0-4.334 6.264-11.676 6.488z"
            fill="#d3b79f"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M532.158 607.8c-18.39-.055-48.935-16.177-54.708-49.171l19.687-3.245H582.615l4.251 4.203s-11.103 48.348-54.708 48.214"
            fill="#d4854a"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M535.85 579.662s-2.907 17.004-4.475 17.9c0 0-3.58-9.397-4.474-17.9 0 0-3.58 8.279-8.056 10.964 0 0-.894-8.28 1.119-12.306 0 0-14.096 4.474-19.243-11.636 0 0-3.804-10.293-23.27-8.055l6.936-4.7-4.7-1.565s6.709-19.773 39.827-26.11c0 0 4.665-5.116 12.962-4.607 0 0 8.969.287 14.339 4.762 0 0 28.639 1.79 38.485 25.06l-2.684.224s4.026 3.706 4.25 7.894c0 0-2.908-1.405-9.397 4.636 0 0-16.335 17.454-33.563 13.202v12.529z"
            fill="#e4d1be"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M558.273 566.312a8.226 8.226 0 10-16.449 0 8.226 8.226 0 0016.45 0"
            fill="#d1874c"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M552.634 566.311c0-2.75-1.158-4.98-2.586-4.98-1.43 0-2.585 2.23-2.585 4.98 0 2.75 1.156 4.982 2.585 4.982 1.428 0 2.586-2.232 2.586-4.982"
            fill="#1d2025"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M554.97 568.398a2.011 2.011 0 10-4.021.001 2.011 2.011 0 004.022-.001"
            fill="#fff"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M524.077 566.312a8.226 8.226 0 10-16.447 0 8.226 8.226 0 0016.447 0"
            fill="#d1874c"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M518.438 566.311c0-2.75-1.157-4.98-2.585-4.98-1.43 0-2.586 2.23-2.586 4.98 0 2.75 1.156 4.982 2.585 4.982 1.428 0 2.585-2.232 2.585-4.982"
            fill="#1d2025"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M520.774 568.398a2.01 2.01 0 10-4.02 0 2.01 2.01 0 004.02 0"
            fill="#fff"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M540.769 548.28c-3.702 0-6.952-1.69-8.831-4.24-1.881 2.55-5.131 4.24-8.831 4.24-5.82 0-10.54-4.17-10.54-9.314s4.72-9.315 10.54-9.315c3.7 0 6.95 1.69 8.83 4.241 1.88-2.55 5.13-4.24 8.832-4.24 5.819 0 10.537 4.17 10.537 9.314 0 5.143-4.718 9.314-10.537 9.314"
            fill="#fdfdfb"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M537.742 546.427c0-2.04-2.492-3.691-5.568-3.691-3.078 0-5.572 1.652-5.572 3.69 0 2.04 2.494 3.693 5.572 3.693 3.076 0 5.568-1.653 5.568-3.692"
            fill="#db806b"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M539.038 538.4c1.082 0 1.082 1.677 0 1.677s-1.082-1.678 0-1.678"
            fill="#d4854a"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M545.515 541.509c1.082 0 1.082 1.678 0 1.678s-1.082-1.678 0-1.678"
            fill="#d4854a"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M545.902 536.586c1.082 0 1.082 1.678 0 1.678s-1.082-1.678 0-1.678"
            fill="#d4854a"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M539.814 534.125c1.082 0 1.082 1.678 0 1.678s-1.082-1.678 0-1.678"
            fill="#d4854a"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M522.458 533.477c1.082 0 1.082 1.678 0 1.678s-1.082-1.678 0-1.678"
            fill="#d4854a"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M524.401 538.011c1.082 0 1.082 1.678 0 1.678s-1.082-1.678 0-1.678"
            fill="#d4854a"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M517.925 537.104c1.082 0 1.082 1.678 0 1.678s-1.082-1.678 0-1.678"
            fill="#d4854a"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M519.737 541.768c1.082 0 1.082 1.678 0 1.678s-1.082-1.678 0-1.678"
            fill="#d4854a"
          />
        </g>
      </svg>
      <svg
        className="Cat6 Cat"
        viewBox="693.436 389.432 209.448 202.17"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <clipPath id="prefix__a">
            <path d="M0 1000h1000V0H0z" fill="none" />
          </clipPath>
        </defs>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M853.781 518.527c0-4.125-.596-7.47-1.33-7.47-.733 0-1.329 3.345-1.329 7.47 0 4.126.596 7.471 1.33 7.471.733 0 1.33-3.345 1.33-7.47"
            fill="#aa837e"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M836.564 572.278s1.221 15.439-25.966 36.919c0 0-9.397 5.37-7.72-8.39 0 0 4.364-16.111 2.014-28.53 0 0 7.05-11.275 4.7-17.72 0 0 .44-5.013 8.054-9.13l16.167 11.98z"
            fill="#c99690"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M817.441 550.288c-2.453 1.76-3.37 3.405-3.686 4.171 1.554 6.66-2.617 15.056-4.646 18.61 1.78 12.061-1.668 26.188-2.263 28.49-.329 2.915-.007 4.184.17 4.628.48-.042 1.025-.227 1.396-.396 23.241-18.474 24.118-31.66 24.137-33.07l-2.408-13.02z"
            fill="#f6c9c4"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M868.997 572.617s-1.22 15.44 25.966 36.92c0 0 9.397 5.37 7.72-8.39 0 0-4.364-16.112-2.014-28.53 0 0-7.049-11.276-4.699-17.72 0 0-.44-5.014-8.056-9.13l-16.166 11.981z"
            fill="#c99690"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M888.12 550.627c2.453 1.76 3.37 3.405 3.686 4.171-1.554 6.66 2.617 15.057 4.646 18.61-1.779 12.061 1.668 26.188 2.263 28.49.33 2.915.007 4.185-.169 4.628-.48-.04-1.026-.227-1.397-.396-23.24-18.473-24.118-31.66-24.137-33.07l2.408-13.02z"
            fill="#f6c9c4"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M821.29 443.766s3.093-5.094 11.135-21.8c0 0 1.236-10.516 8.042-10.516 0 0 21.654-3.713 22.891 5.568 0 0 2.293 10.773-15.466 10.223 0 0-11.756 10.84-12.993 26.603z"
            fill="#cd9a94"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M768.082 432.28s19.799 2.06 20.417-12.169c0 0 .619-3.094-33.408-3.094l-3.094 15.262z"
            fill="#cd9a94"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M830.568 522.376s1.872-7.494-14.832-7.604c0 0-10.517 6.762-21.034-4.406 0 0-9.556-3.06-21.173 1.237 0 0-45.026 17.909-70.392-26.017 0 0-11.754-20.209-3.092-53.412l6.803-10.932 11.755-4.33 16.086-3.094 14.85 3.094 14.207 4.949 4.351 10.313 5.186 3.726s29.063-.755 62.053 8.379c0 0 21.233 5.424 25.564 26.768 0 0 8.84 22.838 9.987 28.897 0 0 3.622 11.584 3.622 22.432z"
            fill="#e2afa6"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M696.858 452.54s-11.682-23.149 8.117-38.616c0 0 15.501-8.635 40.85-4.317 0 0 28.353 3.334 33.391 34.862 1.562 9.771-3.093 22.42-30.314 25.445 0 0-9.899 1.678-25.366-4.574 0 0-14.229-8.178 1.237-5.668 0 0 22.766 7.252 38.431-4.223 5.352-3.92 14.686-11.474 1.034-26.519-5.882-6.48-16.684-13.028-33.642-9.686 0 0-30.73 4.404-33.738 33.295"
            fill="#aa807c"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M826.672 516.745s40.398-21.815 47.822 5.736c0 0-.423-11.96-3.622-22.432 0 0-8.806-7.135-14.993-15.383 0 0-3.092-12.889-9.795-23.457 0 0-9.025-8.25-6.446 5.154 0 0 10.107 23.975 2.734 27.07 0 0-4.282 5.411-10.984 9.278 0 0-9.8 7.254-11.345 12.392z"
            fill="#cd9a93"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M826.75 460.693s-12.116 20.623-45.37 32.48c0 0-18.45 6.433-16.755-10.568 0 0-.516-11.148 20.364-42.953 0 0 6.445-11.957 8.507-18.4 0 0 2.577-10.76 10.053-10.536 0 0 6.953.026 16.494-.003 0 0 9.349 8.218 0 15.437-1.943 1.5-4.637 1.031-9.792.773 0 0-8.766 7.475-17.787 34.541 0 0 8.245-9.794 26.549-11.857 0 0 16.695.48 7.737 11.086"
            fill="#cd9a93"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M892.33 548.125c-2.772 31.751-38.96 30.437-38.96 30.437s-35.787 1.314-38.558-30.437c0 0-2.637-18.2 13.657-30.285 0 0 7.714-16.842 25.302-16.467 11.118-.005 21.915 15.098 21.915 15.098 18.994 12.175 16.643 31.655 16.643 31.655"
            fill="#e2afa6"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M859.164 543.714c0-10.202-3.005-18.473-6.713-18.473-3.707 0-6.712 8.27-6.712 18.473 0 10.2 3.005 18.473 6.712 18.473 3.708 0 6.713-8.272 6.713-18.473"
            fill="#aa837e"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M860.015 528.985c-3.086 0-5.82-1.472-7.563-3.745-1.744 2.273-4.48 3.745-7.565 3.745-5.268 0-9.537-4.27-9.537-9.538 0-5.266 4.269-9.537 9.537-9.537 3.086 0 5.82 1.472 7.565 3.744 1.743-2.272 4.477-3.744 7.563-3.744a9.537 9.537 0 019.538 9.537 9.537 9.537 0 01-9.538 9.538"
            fill="#f6cac5"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M858.123 526.838c0-2.045-2.593-3.703-5.789-3.703-3.199 0-5.793 1.658-5.793 3.703s2.594 3.704 5.793 3.704c3.196 0 5.789-1.66 5.789-3.704"
            fill="#363737"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M821.214 552.037s8.4 7.637 16.8 4.71c4.382-1.528 7.725-6.492 6.364-17.438 0 0-11.43-4.072-15.401-1.399-3.343 2.25-7.863 7.381-7.763 14.127"
            fill="#ce9895"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M841.519 546.852a7.705 7.705 0 10-15.41 0 7.705 7.705 0 0015.41 0"
            fill="#87d7f0"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M836.564 546.976c0-2.967-1.23-5.375-2.75-5.375s-2.751 2.408-2.751 5.375c0 2.97 1.23 5.377 2.75 5.377s2.751-2.407 2.751-5.377"
            fill="#282423"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M838.169 549.386a2.007 2.007 0 10-4.016 0 2.007 2.007 0 004.016 0"
            fill="#fff"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M883.937 552.037s-8.4 7.637-16.8 4.71c-4.382-1.528-7.724-6.492-6.364-17.438 0 0 11.429-4.072 15.4-1.399 3.344 2.25 7.863 7.381 7.763 14.127"
            fill="#ce9895"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M863.633 546.852a7.705 7.705 0 1115.41 0 7.705 7.705 0 01-15.41 0"
            fill="#87d7f0"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M868.587 546.976c0-2.967 1.23-5.375 2.75-5.375s2.75 2.408 2.75 5.375c0 2.97-1.23 5.377-2.75 5.377s-2.75-2.407-2.75-5.377"
            fill="#282423"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M866.982 549.386a2.006 2.006 0 114.015-.001 2.006 2.006 0 01-4.015 0"
            fill="#fff"
          />
        </g>
      </svg>
      <svg
        className="Cat7 Cat"
        viewBox="109.008 697.615 191.204 226.207"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <clipPath id="prefix__a">
            <path d="M0 1000h1000V0H0z" fill="none" />
          </clipPath>
        </defs>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M147.092 282.54c10.692-15.235 13.04-32.026 5.24-37.497-7.797-5.473-22.788 2.441-33.48 17.676-10.691 15.234-13.04 32.023-5.24 37.496 7.798 5.472 22.789-2.442 33.48-17.676"
            fill="#526b6f"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M146.424 248.68c-5.544 0-15.286 5.88-23.175 17.125-4.624 6.588-7.77 13.902-8.632 20.066-.677 4.84.1 8.559 2.079 9.95.729.509 1.652.757 2.825.757 5.544 0 15.285-5.882 23.175-17.125 4.624-6.587 7.77-13.902 8.632-20.066.677-4.84-.101-8.561-2.08-9.947-.73-.512-1.652-.76-2.824-.76"
            fill="#7e9197"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M180.012 282.975c-10.692-15.234-13.039-32.023-5.24-37.496 7.797-5.473 22.788 2.443 33.48 17.676 10.691 15.236 13.04 32.023 5.24 37.496-7.798 5.474-22.789-2.442-33.48-17.676"
            fill="#526b6f"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M180.68 249.115c5.544 0 15.286 5.883 23.175 17.125 4.624 6.588 7.77 13.902 8.632 20.066.677 4.84-.1 8.561-2.079 9.95-.729.51-1.652.76-2.825.76-5.544 0-15.285-5.885-23.175-17.128-4.624-6.587-7.77-13.9-8.632-20.066-.677-4.84.101-8.559 2.08-9.947.73-.512 1.652-.76 2.824-.76"
            fill="#7e9197"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M232.428 123.441s33.021 15.52 64.391-4.293c2.411-1.523 8.04-14.51-4.292-10.566-15.495 4.953-29.059 16.507-65.052-1.323z"
            fill="#667e86"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M208.412 197.135s18.348-14.95 27.746-44.04c0 0 16.557-38.036-13.873-51.91 0 0-10.059-6.56-13.873-7.607-5.532-1.517-15.139-.52-22.523-4.386 0 0-9.25 7.697-47.736 0l-4.028 3.49s-22.636-3.385-20.34 6.713c1.54 6.783 5.573 8.95 24.368 13.426l-3.132 23.269 8.503 25.06 12.082 22.725z"
            fill="#51696d"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M118.463 209.035s-11.636-22.375 1.79-55.492c0 0 .448-28.194 10.74-42.96 0 0 7.16-12.083.449-16.11 0 0-12.983-5.426-8.426-14.975 0 0 11.718-7.162 21.266 2.17 0 0 5.642 6.076 7.812 23.437 0 0 1.302 19.532-1.085 27.778 0 0 12.37-1.084 21.267.435 0 0-3.254-22.57 3.907-41.232 0 0 1.954-13.889 14.54-15.844 0 0 13.237-1.303 13.02 7.813 0 0 0 4.992-11.284 11.503v9.112s13.888 22.57 12.586 48.392c0 0 10.528 8.088 6.948 65.371z"
            fill="#637c83"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M121.148 204.111s4.027-39.379 39.828-40.275c0 0 34.012-5.37 43.857 39.83z"
            fill="#9eacaf"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M162.12 291.963c-16.584.215-46.342-14.01-47.67-46.143 0 0-2.804-12.49 1.02-25.492 0 0 10.45-8.412 19.119-13.765 8.668-5.354 19.374-5.354 27.532-7.393 8.158-2.04 15.55-3.312 19.63 0 4.078 3.313 11.217 6.119 15.295 9.178 4.079 3.058 15.55 16.316 16.06 18.863.51 2.549.256 14.787 0 19.119 0 0-1.184 44.988-50.985 45.633"
            fill="#637c83"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M116.015 254.799s-8.958-15.605-4.37-40.334c0 0 3.059-16.57 23.963-18.607 0 0 15.297-.256 34.67-.256 0 0 33.652-6.12 40.79 9.43 0 0 14.155 10.884 1.087 47.89 0 0 1.206-24.945-10.52-34.377 0 0-4.843-3.822-9.432-5.096 0 0-2.55.764-14.021-6.884 0 0-11.726-10.705-26.767-1.274 0 0-16.064 11.469-21.926 11.727 0 0-21.85 4.439-13.474 37.78"
            fill="#4d6166"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M217.693 107.26s11.763-.99 12.423-13.207c0 0 .25-6.936-12.423-6.605 0 0-14.04-2.641-12.86 7.925 0 0 1.427 11.227 12.86 11.887"
            fill="#637c83"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M163.613 234.72s25.484 5.944 26.805-10.112c.576-6.996-11.557-13.991-27.39-6.174 0 0-18.263-14.367-27.071 6.174-1.947 4.539 10.054 17.7 27.656 10.113"
            fill="#9facb0"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M170.096 233.725c0-2.98-3.592-5.396-8.021-5.396-4.43 0-8.021 2.416-8.021 5.396 0 2.982 3.59 5.4 8.02 5.4s8.022-2.418 8.022-5.4"
            fill="#393a3a"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M145.682 227.941c1.082 0 1.082 1.678 0 1.678s-1.082-1.678 0-1.678"
            fill="#5c6c70"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M141.112 223.006c1.082 0 1.082 1.678 0 1.678s-1.082-1.678 0-1.678"
            fill="#5c6c70"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M146.961 220.994c1.082 0 1.082 1.678 0 1.678s-1.082-1.678 0-1.678"
            fill="#5c6c70"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M152.263 221.543c1.082 0 1.082 1.678 0 1.678s-1.082-1.678 0-1.678"
            fill="#5c6c70"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M174.748 220.447c1.082 0 1.082 1.678 0 1.678s-1.082-1.678 0-1.678"
            fill="#5c6c70"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M179.684 220.447c1.082 0 1.082 1.678 0 1.678s-1.082-1.678 0-1.678"
            fill="#5c6c70"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M185.534 223.006c1.082 0 1.082 1.678 0 1.678s-1.082-1.678 0-1.678"
            fill="#5c6c70"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M180.232 227.576c1.082 0 1.082 1.678 0 1.678s-1.082-1.678 0-1.678"
            fill="#5c6c70"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M130.378 250.541s2.35 10.908 14.432 10.74c0 0 9.492-.353 11.076-11.748 0 0-.336-9.396-11.244-9.564 0 0-9.901.168-14.264 10.572"
            fill="#506666"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M154.461 250.121a9.315 9.315 0 10-18.629.001 9.315 9.315 0 0018.63 0"
            fill="#f7a03a"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M148 249.701c0-4.076-1.277-7.383-2.853-7.383-1.575 0-2.853 3.307-2.853 7.383 0 4.078 1.278 7.385 2.853 7.385 1.576 0 2.853-3.307 2.853-7.385"
            fill="#2b323a"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M148.724 254.799a1.762 1.762 0 10-3.525 0 1.762 1.762 0 003.525 0"
            fill="#fff"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M195.604 250.541s-2.35 10.908-14.432 10.74c0 0-9.492-.353-11.076-11.748 0 0 .336-9.396 11.243-9.564 0 0 9.902.168 14.265 10.572"
            fill="#506666"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M171.52 250.121a9.315 9.315 0 1118.625.001 9.315 9.315 0 01-18.625 0"
            fill="#f7a03a"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M177.981 249.701c0-4.076 1.277-7.383 2.853-7.383 1.575 0 2.853 3.307 2.853 7.383 0 4.078-1.278 7.385-2.853 7.385-1.576 0-2.853-3.307-2.853-7.385"
            fill="#2b323a"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M181.088 254.799a1.762 1.762 0 113.524 0 1.762 1.762 0 01-3.524 0"
            fill="#fff"
          />
        </g>
      </svg>
      <svg
        className="Cat8 Cat"
        viewBox="319.038 718.508 307.832 192.707"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <clipPath id="prefix__a">
            <path d="M0 1000h1000V0H0z" fill="none" />
          </clipPath>
        </defs>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M511.336 227.18s-38.871 71.789 27.578 30.332z"
            fill="#ddab86"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M512.64 236.596c-5.295 11.52-8.853 23.86-6.605 27.63.144.24.482.807 2.455.807 3.29 0 9.72-1.613 22.05-8.748z"
            fill="#ffeee1"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M607.443 228.103s34.616 73.936-29.3 28.672z"
            fill="#ddab86"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M605.593 237.428c4.614 11.81 7.445 24.338 4.982 27.967-.16.234-.528.78-2.498.666-3.286-.192-9.611-2.178-21.504-10.022z"
            fill="#ffeee1"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M451.008 123.336s.67-11.746 7.048-15.104c0 0 11.076-6.376 22.823-5.705 0 0 9.733.336 2.35 7.719 0 0-2.138 3.848-8.95 7.049 0 0 .895 10.068-1.791 13.762z"
            fill="#e8bc9b"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M319.038 257.54s13.352 8.245 32.2 9.03c0 0-3.534 5.106-11.78 5.498 0 0 20.025 7.067 35.732-3.535 0 0-2.749 8.64-2.356 12.96 0 0 20.026-16.1 39.267-19.636 0 0-5.105 7.461-1.963 11.39 0 0 34.554-19.634 32.198-63.22 0 0 35.34 5.498 62.041 0l60.471-12.959 27.093-56.543s-.392-15.707 1.963-16.884c0 0-7.853 4.318-9.03-1.965l-2.75-16.1s16.492-2.353 18.062-14.92c0 0-28.27-3.928-39.658 2.748 0 0 1.57 22.774-8.247 27.096 0 0-1.178-3.535-4.712-6.283 0 0-2.748 5.888-4.712 5.888 0 0-1.177-3.925-2.355-7.459l-5.498 5.104-18.848-11.387s11.388-9.03 11.78-14.922c0 0-17.669-8.246-40.05 4.321 0 0-.786 23.951 1.176 26.308 0 0-2.356 1.571-6.282-4.713l-2.356 5.891-4.319-2.748-.786 5.103-7.853-5.103s-3.927 1.57-2.356 6.281l-18.455-14.922s12.171-8.246 12.957-15.312c0 0-21.988-7.069-38.48 5.105 0 0-14.92 37.301-8.246 69.893 0 0-4.712 5.103-11.387 24.738 0 0-1.57 9.424-13.743 1.57 0 0-9.818-3.142-15.315 1.178 0 0 7.068 6.283 8.246 13.742 0 0-9.423-10.994-26.7-9.814 0 0 5.104 9.814 5.497 18.846 0 0-6.282-8.245-21.204-7.067 0 0 2.75 5.89 5.497 7.854 0 0-11.78 2.748-24.739 18.06 0 0 9.818-1.963 14.53-1.963l.785 2.358s-11.78 1.572-15.315 16.492"
            fill="#f3d0b0"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M495.759 217.537s-18.348-10.293-15.215-46.54l-6.265-7.16s6.265-7.609 13.425-6.714c0 0-1.79-11.635 3.133-17.899 0 0 4.922 7.16 8.502 5.37 0 0-2.237-6.713 7.16-14.32 0 0 1.789 7.16 4.923 7.607 0 0 2.237-6.713 8.95-10.293 0 0 2.238 10.293 7.608 7.607 0 0 2.127-8.179 8.502-11.635 0 0 4.028 7.161 6.713 8.502 0 0-.05-6.21 6.265-10.29 0 0 5.37 6.712 6.265 9.396 0 0 6.265-8.502 5.37-12.53 0 0 9.845 6.264 14.32 12.083 0 0 3.58-4.922 3.58-8.055 0 0 4.923 4.922 7.609 10.293 0 0 4.514-4.75 3.376-7.969 0 0 2.44 4.387 5.126 9.311 0 0 5.687-4.922 3.739-8.502 0 0 5.659 8.502 6.106 19.242 0 0 4.923-4.029 4.923-8.502 0 0 5.818 15.662 1.79 20.137 0 0 7.609.894 8.055 2.685 0 0-4.923 3.133-4.027 15.215 0 0 .894 16.56-7.608 25.955l-66.3 1.48z"
            fill="#e8bb9b"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M569.653 263.58s-9.045 3.76-22.655 0c0 0-7.968 6.447-7.968 8.06 0 0-2.418-4.57-.806-8.06l-7.52 1.881v-4.836s-2.419 1.881-5.643.537c0 0 1.613-3.225 0-5.643 0 0-11.55-8.056-17.46-19.607l-7.253-1.209s1.612-1.746 3.492-5.775c0 0-13.37-9.094-13.4-16.098 0 0 11.192 2.604 11.49-2.334 0 0-6.893-1.178-11.49-9.506 0 0 13.401 1.076 14.744-2.685 0 0-6.716-.805-7.522-5.64 0 0 7.254 1.343 11.283-1.075 0 0-5.91-3.233-5.373-6.45 0 0 15.311 1.876 17.998 0l16.654-7.789s22.834-15.847 39.22 1.077l20.414 6.711s9.134-.004 15.043-.538c0 0-1.343 5.377-4.029 6.72 0 0 2.622 2.685 10.846 2.15 0 0-2.777 3.65-7.89 5.373 0 0 5.908 5.101 15.042 1.88 0 0-4.568 6.444-11.014 9.936 0 0-.269 3.494 11.014 2.955 0 0-.269 5.908-12.625 13.97 0 0-2.418 3.222 4.03 7.518 0 0-5.374.54-7.522-.537 0 0-8.328 15.313-19.61 21.76 0 0-1.342 1.88 1.075 4.297 0 0-4.297 1.611-6.714-.537 0 0 .805 2.955.537 5.103 0 0-3.224-.269-5.642-2.685 0 0 .001 6.451-2.148 9.135 0 0-1.077-3.76-8.598-8.059"
            fill="#f3d0b0"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M507.982 203.576s11.454-1.908 23.673 4.201c12.22 6.108 27 1.526 27 1.526s11.373 4.201 27.026-3.055c5.135-2.379 12.41-3.81 22.528-2.192 0 0 .382-4.49-9.545-7.736 0 0 6.545-.156 8.781-2.29 0 0-1.718-2.864-10.118-4.774 0 0 4.566-3.633 9.546-2.672 0 0-5.918-13.936-24.627-13.172 0 0-19.118.572-21.873 16.801h-3.327s-5.918-16.479-24.627-16.83c0 0-17.173.367-23.629 12.267 0 0 8.547 1.317 10.075 3.608 0 0-5.365-1.682-9.928 4.2 0 0 1.718 2.673 8.018 3.245 0 0-8.533 1.1-8.973 6.873"
            fill="#ffeddf"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M562.917 184.438a4.82 4.82 0 00-9.641 0c0 2.66 2.717 24.865 5.379 24.865 2.662 0 4.262-22.205 4.262-24.865"
            fill="#deab81"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M563.332 209.588a4.677 4.677 0 10-9.354.002 4.677 4.677 0 009.354-.002"
            fill="#f89992"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M520.01 224.193s6.819-1.57 11.455 2.863c3.197 3.06 12.6 4.393 17.372-3.37 0 0 4.67-11.526-7.063-14.098 0 0-8.018-1.624-13.745 2.386 0 0-1.63 2.746-9.164 1.53 0 0 2.482 3.486 6.109 4.033 0 0-4.963 3.984-4.963 6.656"
            fill="#e9ba9a"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M547.401 218.953a8.225 8.225 0 00-8.226-8.225 8.224 8.224 0 00-8.225 8.225 8.226 8.226 0 1016.451 0"
            fill="#de8b37"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M541.43 219.004c0-3.135-1.19-5.676-2.656-5.676-1.468 0-2.657 2.54-2.657 5.676 0 3.135 1.189 5.676 2.657 5.676 1.467 0 2.656-2.541 2.656-5.676"
            fill="#454441"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M542.87 222.008a1.844 1.844 0 10-3.687 0 1.844 1.844 0 003.687 0"
            fill="#fff"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M596.911 224.193s-6.818-1.57-11.454 2.863c-3.197 3.06-12.6 4.393-17.373-3.37 0 0-4.669-11.526 7.063-14.098 0 0 8.02-1.624 13.746 2.386 0 0 1.63 2.746 9.164 1.53 0 0-2.483 3.486-6.11 4.033 0 0 4.964 3.984 4.964 6.656"
            fill="#e9ba9a"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M569.52 218.953a8.225 8.225 0 0116.45 0 8.226 8.226 0 11-16.45 0"
            fill="#de8b37"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M575.492 219.004c0-3.135 1.19-5.676 2.656-5.676 1.468 0 2.657 2.54 2.657 5.676 0 3.135-1.189 5.676-2.657 5.676-1.467 0-2.656-2.541-2.656-5.676"
            fill="#454441"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M578.56 222.41a1.844 1.844 0 113.688 0 1.844 1.844 0 01-3.688 0"
            fill="#fff"
          />
        </g>
      </svg>
      <svg
        className="Cat9 Cat"
        viewBox="762.286 699.363 195.664 240.687"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <clipPath id="prefix__a">
            <path d="M0 1000h1000V0H0z" fill="none" />
          </clipPath>
        </defs>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M789.678 244.164s-23.326 17.76-17.902 52.584c1.568 10.068 26.18.223 42.068-25.285z"
            fill="#7d7163"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M789.362 249.883c-5.64 5.725-17.52 21.115-13.607 46.244a.955.955 0 00.077.283c.05.032.448.2 1.282.2 6.515 0 20.767-8.59 31.685-24.77z"
            fill="#9a8e82"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M860.39 242.957s23.327 17.758 17.903 52.582c-1.568 10.068-26.18.223-42.068-25.283z"
            fill="#7d7163"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M860.706 248.676c5.64 5.725 17.521 21.115 13.607 46.244a.955.955 0 01-.077.283c-.05.032-.448.2-1.282.2-6.515 0-20.768-8.59-31.685-24.77z"
            fill="#9a8e82"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M860.66 109.273s32.222-.945 67.76-5.213c0 0 22.744-1.42 28.903-18.478 0 0 4.267-11.846-9.476-3.79 0 0-4.268 10.461-24.64 11.39 0 0-46.437 3.298-62.547 3.771z"
            fill="#7c7064"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M798.113 185.088s-17.53-4.49-17.059-66.451c0 0-14.213-10.783-10.423-34.002l13.42-5.651s45.735-11.105 80.873 2.333l9.951 4.738s3.315 24.166-11.847 31.748c0 0 4.265 36.959-10.425 67.285z"
            fill="#d2c1af"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M801.985 216.195s-12.53-9.846-11.189-52.807c0 0-8.18-25.617 1.664-56.529l15.79-5.227v36.696s14.766-6.713 26.85.447c0 0 2.685-24.164 2.685-39.828l16.11 7.16s9.398 36.248 0 61.756c0 0 8.056 24.166-7.16 48.332z"
            fill="#b8a899"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M791.21 111.143s4.89-29.646 0-40.387c0 0-2.651-11.638 10.773-10.74 0 0 15.665-.894 7.162 11.633 0 0-.896 24.166-.896 33.564 0 0-7.226 6.489-17.038 5.93"
            fill="#7b6f63"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M837.73 102.984s10.862 8.561 16.896 6.29c0 0-5.085-29.122 0-36.729 0 0 2.85-13.077-10.576-12.58 0 0-14.768-.399-8.055 12.132 0 0 2.42 17.043 1.735 30.887"
            fill="#7b6f63"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M875.182 90.445s-14.176-.352-17.364-11.842c0 0 1.448-6.955 13.53-6.058 0 0 12.533-1.442 12.53 7.336 0 0 1.403 8.773-8.696 10.564"
            fill="#7b6f63"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M771.049 90.445s14.176-.352 17.364-11.842c0 0-1.447-6.955-13.53-6.058 0 0-12.532-1.442-12.531 7.336 0 0-1.402 8.773 8.697 10.564"
            fill="#7b6f63"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M863.457 237.426c-1.286 39.58-38.513 39.605-38.513 39.605s-37.392-.025-38.678-39.605c0 0-.936-11.902 12.985-24.623 0 0 6.726-17.27 25.702-17.238 18.977-.032 25.518 17.238 25.518 17.238 13.921 12.72 12.986 24.623 12.986 24.623"
            fill="#d3c3b1"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M855.345 242.957c-6.504 18.641-30.484 16.998-30.484 16.998s-24.385 1.643-30.89-16.998c0 0-4.424-21.244 14.828-22.545 0 0-3.661-19.51 15.657-19.51 19.32 0 16.063 19.51 16.063 19.51 19.25 1.301 14.826 22.545 14.826 22.545"
            fill="#7b6f63"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M811.965 205.791s11.592-3.916 10.875 11.299h4.206s-4.05-12.539 9.61-11.752c0 0-3.156-4.266-12.2-4.436 0 0-8.032-.466-12.491 4.889"
            fill="#3b3b39"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M829.73 218.434c0-1.855-2.305-3.357-5.146-3.357-2.842 0-5.146 1.502-5.146 3.357 0 1.854 2.304 3.355 5.147 3.355 2.84 0 5.146-1.501 5.146-3.355"
            fill="#3b3b39"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M798.852 242.957c1.88 4.084 13.112 9.262 17.9-1.701 0 0 1.343-6.85 0-11.256 0 0-13.414-5.816-17.9 4.096 0 0-1.944 4.634 0 8.861"
            fill="#595245"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M815.288 238.625a7.617 7.617 0 00-7.618-7.617 7.617 7.617 0 00-7.619 7.617 7.618 7.618 0 007.62 7.619 7.617 7.617 0 007.617-7.619"
            fill="#6e92d5"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M810.315 238.234c0-3.273-1.184-5.93-2.646-5.93-1.46 0-2.646 2.657-2.646 5.93 0 3.275 1.186 5.93 2.646 5.93 1.462 0 2.646-2.655 2.646-5.93"
            fill="#353031"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M812.485 241.2a2.574 2.574 0 10-5.147-.001 2.574 2.574 0 005.147 0"
            fill="#fff"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M851.117 242.957c-1.879 4.084-13.112 9.262-17.9-1.701 0 0-1.343-6.85 0-11.256 0 0 13.414-5.816 17.9 4.096 0 0 1.944 4.634 0 8.861"
            fill="#595245"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M834.682 238.625a7.617 7.617 0 017.618-7.617 7.617 7.617 0 017.619 7.617 7.618 7.618 0 01-7.62 7.619 7.617 7.617 0 01-7.617-7.619"
            fill="#6e92d5"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M839.654 238.234c0-3.273 1.184-5.93 2.646-5.93 1.46 0 2.645 2.657 2.645 5.93 0 3.275-1.185 5.93-2.645 5.93-1.462 0-2.646-2.655-2.646-5.93"
            fill="#353031"
          />
        </g>
        <g transform="matrix(1 0 0 -1 0 1000)" clipPath="url(#prefix__a)">
          <path
            d="M841.97 241.2a2.574 2.574 0 115.148-.001 2.574 2.574 0 01-5.147 0"
            fill="#fff"
          />
        </g>
      </svg>
    </div>
  );
}

export default Cats;
